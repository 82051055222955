<script setup>
import { onMounted } from 'vue';
import MobileListingCard from './partials/listing-card.vue';
import MobileListingListsFavorite from './partials/listing-lists-favorite';
import MobileListingsSortButton from './partials/sort-button';
import { useMetaStore } from '~/stores/meta.store';

const props = defineProps({
  data: {
    type: Object
  },
  fetchData: {
    type: Function,
    required: true,
  },
})
const route = useRoute();
const router = useRouter();
const page = ref(route.query?.page || 1);
const metaStore = useMetaStore();
const pageData = ref(props.data);
const scrollPosition = ref(0);
const isAtBottom = ref(false);
const isLoading = ref(false);
const endOfPage = ref(false);
const categorySlug=pageData.value.selected_filters.category.value?.slug

metaStore.setMeta(pageData.value.meta);

const checkScroll = () => {
  scrollPosition.value = window.scrollY;
  isAtBottom.value = window.innerHeight + window.scrollY >= document.body.offsetHeight;
}

onMounted(async () => {
  window.addEventListener('scroll', checkScroll);
})


watch(() => props.data, () => pageData.value = props.data);

watch(() => route.query, async () => {
  if (isLoading.value == true) {
    return;
  }
  if (route.query?.page == 1) {
    page.value = 1
  }

  isLoading.value = true;
  // Update query
  await router.push({
    query: { ...route.query },
  });

  // Fetch and update new page data
  const { data } = await props.fetchData();

  if (data) {
    endOfPage.value = false;
    pageData.value.data.listings = data.data.listings;
  }
  isLoading.value = false;
})

watch(isAtBottom, async () => {
  if (isLoading.value || endOfPage.value) {
    return;
  }
  isLoading.value = true;
  // Update query
  await router.push({
    query: { ...route.query, page: ++page.value },
  });

  // Fetch and update new page data
  const { data } = await props.fetchData();

  if (data.data.listings.length) {
    pageData.value.data.listings.push(...data.data.listings);
  } else {
    endOfPage.value = true;
  }
  isLoading.value = false;
});

const setSelectedFilter = async () => {
  const filtersStore = useFiltersStore();
  await filtersStore.fetchFilters(pageData.value.data.category.id);
  const localePath = useLocalePath()
  filtersStore.selected_filters = pageData?.value.selected_filters;
  const path = localePath({ name: 'search' });
  
  router.push(`${path}?category=${categorySlug}`);
}
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.property')"
        :subTitle="`${pageData.data.listing_count} ${$t('client.property')}`">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
        <template #header-right>
          <div class="flex items-center gap-4">
            <mobile-listing-lists-favorite></mobile-listing-lists-favorite>
            <mobile-share-button></mobile-share-button>
          </div>
        </template>
      </mobile-header>
    </template>

    <div class="flex bg-white p-4 items-center justify-between text-gray-500 shadow">
      <button @click="setSelectedFilter" class="flex items-center gap-1 text-sm">
        <i class="fa-solid fa-filter"></i>
        <span>Filter</span>
      </button>
      <mobile-listings-sort-button></mobile-listings-sort-button>
      <NuxtLink :to="localePath({ name: 'listings.last_seen' })" class="flex items-center gap-1 text-sm cursor-pointer">
        <i class="fa-solid fa-eye"></i>
        <span>{{ $t('client.last_seen') }}</span>
      </NuxtLink>
      <!-- <a href="" class="flex items-center gap-1 text-sm">
        <i class="fa-solid fa-bookmark"></i>
        <span>Saved Searches</span>
      </a> -->
    </div>
    <div class="max-w-[90%] mx-auto my-2 bg-yellow-100 border border-yellow-400 rounded-md p-3 flex items-center gap-3" v-if="pageData.data.no_result">
         <i class="fa-solid fa-warning text-orange-800 text-2xl"></i>
         <div class="font-normal text-xs text-yellow-800" v-html="pageData.data.no_result"></div>
    </div>

    <div class="flex flex-col item-center px-4 mt-4 relative">
      <template v-for="listing in pageData.data.listings">
        <mobile-listing-card :listing="listing"></mobile-listing-card>
      </template>
    </div>
    <div class="absolute -bottom-5 left-1/2 -translate-x-1/2">
      <span class="loader" v-if="isLoading"></span>
    </div>

  </NuxtLayout>
</template>